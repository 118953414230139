import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../../flash/flash-service";
import {WorkflowService} from "../../workflow/workflow-service";
import {ReportItemPositionsDialog} from "./report-item-positions-dialog";
import {BwaPositionsDialog} from "./bwa-positions-dialog";

@inject(
    Client,
    DialogService,
    FlashService,
    WorkflowService
)
@customElement('sio-accounting-bwa-statement')
export class BwaStatement {

    client;
    dialogService;
    flash;
    form;

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
    }

    submit(print) {

        let data = this.form.formService.getValue();
        this._isSubmitting = true;

        this.data = null;

        let workflowId = print ? 'accounting/generate-bwa-print' : 'accounting/generate-bwa';

        return this.workflowService.trigger(workflowId, null, data).then(data => {

            this.data = data[0].bwaStatement;
            this._isSubmitting = false;

            if (data[0].url) {
                window.location.href = data[0].url;
            }

            return data;
        });
    }

    open(positions) {
        this.dialogService
            .open({
                viewModel: BwaPositionsDialog,
                model: {
                    positions: positions,
                    title: 'BWA Position',
                    headers: this.data.headers
                }
            })
            .whenClosed(response => {
            });
    }
}
