import {customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../../flash/flash-service";
import {WorkflowService} from "../../workflow/workflow-service";
import "./balance-list.less";

@inject(Client, DialogService, FlashService, WorkflowService)
@customElement('sio-accounting-balance-list')
export class BalanceList {

    client;
    dialogService;
    flash;
    form;

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
    }

    submit(print) {
        const data = this.form.formService.getValue();
        data.organization = {id: data.organization.id, modelId: data.organization.modelId};
        data.period = {id: data.period.id, modelId: data.period.modelId};

        this._isSubmitting = true;
        this.data = null;

        return this.workflowService
            .trigger(print ? 'accounting/generate-balance-list-print' : 'accounting/generate-balance-list', null, data)
            .then(data => {

                this.data = data[0].list;
                this._isSubmitting = false;

                if (data[0].url) {
                    window.location.href = data[0].url;
                }

                return data;
            });
    }
}
