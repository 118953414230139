import React from "react"
import {Icon, Tooltip, Whisper} from "rsuite"
import {useSelector} from "react-redux"
import {selectAccountingStates} from "../store/state-slice"

function StateIcon({state, cleared}) {
    const states = useSelector(selectAccountingStates)
    const label = cleared ? "ausgeziffert" : states[state].label
    const icon = cleared ? " sio-icon-archive" : states[state].icon

    return (
        <Whisper trigger="hover" delayShow={500} placement="bottomStart" speaker={<Tooltip>{label}</Tooltip>}>
            <Icon icon={icon}/>
        </Whisper>
    )
}

export default StateIcon
