import React, {useState} from "react"
import {Container} from "aurelia-dependency-injection"
import {AureliaConfiguration} from "aurelia-configuration"
import {useSelector} from "react-redux"
import {Button} from "rsuite"
import {AuthTokenStorage} from "../../auth/auth-token-storage"
import {selectContext, selectOrganization, selectQueryParams} from "../store/entries-slice"
import {selectCurrentPeriod} from "../store/state-slice"
import {useAccountingEntriesQuery} from "../store/accounting-api"
import {FlashService} from "../../flash/flash-service"

export default function ExcelDownload() {
    const context = useSelector(selectContext)
    const organization = useSelector(selectOrganization)
    const period = useSelector(selectCurrentPeriod(organization))
    const params = useSelector(selectQueryParams)
    const [loading, setLoading] = useState(false)
    const {isFetching, data = []} = useAccountingEntriesQuery({context, organization, period, ...params})
    const ids = Array.from(new Set(data.map(({booking}) => booking).values()))

    return (
        <Button size="sm" loading={isFetching || loading} disabled={!ids.length}
                onClick={() => doDownload(ids, setLoading)}>
            <span className="glyphicon glyphicon-export"/>
        </Button>
    )
}

function doDownload(ids, setLoading) {
    setLoading(true)
    fetch(Container.instance.get(AureliaConfiguration).get("apiUrl") + "accounting/excel-download", {
        method: "PUT",
        body: JSON.stringify(ids),
        cache: "no-store",
        headers: {
            authorization: "Bearer " + Container.instance.get(AuthTokenStorage).getAccessToken(),
            "content-type": "application/json"
        }
    }).then(resp => resp.blob()).then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        // the filename you want
        a.download = 'buchhaltung.xlsx'
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        setLoading(false)
    }).catch(e => {
        console.error(e)
        Container.instance.get(FlashService).error(e?.message)
    })
}
