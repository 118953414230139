import React from "react"
import {useSelector} from "react-redux"
import {Container} from "aurelia-dependency-injection"
import {I18N} from "aurelia-i18n"
import {Icon, IconButton} from "rsuite"
import {confirm} from "../../dialog/confirm"
import {selectSelected} from "../store/entries-slice"
import {useClearBookingsMutation} from "../store/accounting-api"

const i18n = Container.instance.get(I18N)

export default function ClearButton() {
    const selected = useSelector(selectSelected)
    const [doAction, {isLoading}] = useClearBookingsMutation()
    const label = i18n.tr("accounting.booking.clear")

    function onClick() {
        confirm(label, i18n.tr("sio.are_you_sure")).then(() => doAction(selected))
    }

    return (
        <IconButton
            size="sm" color="yellow"
            loading={isLoading}
            onClick={() => onClick()}
            icon={<Icon icon=" sio-icon-archive"/>}>

            {label}
        </IconButton>
    )
}
