import React from "react"
import {useSelector} from "react-redux"
import {Icon, IconButton} from "rsuite"
import {confirm as doConfirm} from "../../dialog/confirm"
import useAccountingStyles from "../styles"
import {selectAccountingActions} from "../store/state-slice"
import {useAccountingBookingActionMutation} from "../store/accounting-api"

export function Actions({id, state, system, ...props}) {
    const actions = useSelector(selectAccountingActions)

    return state.actions
        .filter(action => (false !== actions[action].system) || !system)
        .map(action => (
            <React.Fragment key={action}><Action id={id} action={action} {...props}/>{" "}</React.Fragment>
        ))
}

export const Action = ({id, action, ...props}) => {
    const {icon, label, confirm, btnClass} = useSelector(selectAccountingActions)[action]
    const [doAction, {isLoading}] = useAccountingBookingActionMutation()
    const className = useAccountingStyles()[btnClass]

    function onClick() {
        (confirm ? doConfirm(label, "Sind Sie sicher?") : Promise.resolve())
            .then(() => doAction({id, action}))
    }

    return (
        <IconButton loading={isLoading} className={className} icon={<Icon icon={icon}/>} onClick={onClick} {...props}>
            {label}
        </IconButton>
    );
}
