import React, {useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Container} from "aurelia-dependency-injection"
import {I18N} from "aurelia-i18n"
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Col,
    DateRangePicker,
    Dropdown,
    FlexboxGrid,
    Icon,
    Input,
    InputGroup,
    Tooltip,
    Whisper
} from "rsuite"
import useAccountingStyles from "../styles"
import {useDebouncedChange} from "../../utilities/debounce"
import {
    selectCanClear,
    selectCanDialogBooking,
    selectCombinedCrDr,
    selectContext,
    selectDateRange,
    selectHasCleared,
    selectOrganization,
    selectSearch,
    selectSelectedStates,
    selectShowCleared,
    selectUsedStates,
    setDateRange,
    setOrganization,
    setSearch,
    toggleCombinedCrDr,
    toggleSelectedState,
    toggleShowCleared
} from "../store/entries-slice"
import {
    selectCurrentPeriod,
    selectOrganizations,
    selectPeriod,
    setCurrentPeriod,
    usePeriodsByOrganization
} from "../store/state-slice"
import EditButton from "../entry/edit-button"
import ExcelDownload from "./excel-download"
import ClearButton from "./clear-button"

const i18n = Container.instance.get(I18N)

export default function Toolbar() {
    const context = useSelector(selectContext)
    const canDialogBooking = useSelector(selectCanDialogBooking)
    const canClear = useSelector(selectCanClear)

    return (
        <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item style={{flexGrow: 1}}>
                <ButtonToolbar style={{marginBottom: "8px"}}>
                    {"journal" === context && <OrganizationSelect/>}
                    {"stack" !== context && <PeriodSelect/>}
                    {"journal" !== context && <StatesSelect/>}
                    <ToggleCombinedCrDr/>
                    <DateRange/>
                </ButtonToolbar>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item componentClass={Col} colspan={8} sm={12} md={4} lg={6} className="text-right">
                {canClear && <><ClearButton/>{" "}</>}

                {("stack" === context || ("account" === context && canDialogBooking)) && (
                    <>
                        <EditButton
                            icon="plus" size="sm" appearance="subtle"
                            label={"stack" === context ? "Hinzufügen" : "Dialogbuchung"}
                        />
                        {" "}
                    </>
                )}

                <ExcelDownload/>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item componentClass={Col} colspan={24} sm={12} md={6} lg={4}>
                <Searchbox/>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )
}

function OrganizationSelect() {
    const dispatch = useDispatch()
    const organization = useSelector(selectOrganization)
    const organizations = useSelector(selectOrganizations)

    return (
        <Dropdown size="sm" title={(organizations.find(({id}) => id === organization)?.label) ?? 'Organisation'}>
            {organizations.map(({id, label}) => (
                <Dropdown.Item key={id} onClick={() => dispatch(setOrganization(id))}>{label}</Dropdown.Item>
            ))}
        </Dropdown>
    )
}

function PeriodSelect() {
    const dispatch = useDispatch()
    const organization = useSelector(selectOrganization)
    const period = useSelector(selectCurrentPeriod(organization))
    const periods = usePeriodsByOrganization(organization)

    function switchPeriod(id) {
        dispatch(setCurrentPeriod(organization, id))
    }

    return (
        <Dropdown size="sm" title={periods.find(({id}) => period === id)?.label ?? "Geschäftsjahr"}>
            <Dropdown.Item onClick={() => switchPeriod(null)}>(alle Geschäftsjahre)</Dropdown.Item>
            {periods.map(({id, label}) => (
                <Dropdown.Item key={id} onClick={() => switchPeriod(id)}>{label}</Dropdown.Item>
            ))}
        </Dropdown>
    )
}

function ToggleCombinedCrDr() {
    const dispatch = useDispatch()
    const combinedCrDr = useSelector(selectCombinedCrDr)

    return (
        <Button size="sm" appearance="subtle" active={combinedCrDr}
                onClick={() => dispatch(toggleCombinedCrDr())}>
            S/H
        </Button>
    )
}

function StatesSelect() {
    const dispatch = useDispatch()
    const list = useSelector(selectUsedStates)
    const states = useSelector(selectSelectedStates)
    const hasCleared = useSelector(selectHasCleared)
    const showCleared = useSelector(selectShowCleared)

    return 2 > list.length + (hasCleared ? 1 : 0) ? null : (
        <ButtonGroup>
            {1 < list.length && list.map(({id, icon, label}) => (
                <Whisper key={id} trigger="hover" delayShow={500} placement="top" speaker={<Tooltip>{label}</Tooltip>}>
                    <Button size="sm" active={states.includes(id)}
                            onClick={() => dispatch(toggleSelectedState(id))}>
                        <Icon icon={icon}/>
                    </Button>
                </Whisper>
            ))}
            {hasCleared && (
                <Whisper trigger="hover" delayShow={500} placement="top" speaker={<Tooltip>{i18n.tr("accounting.booking.cleared")}</Tooltip>}>
                    <Button size="sm" active={showCleared}
                            onClick={() => dispatch(toggleShowCleared())}>
                        <Icon icon=" sio-icon-archive"/>
                    </Button>
                </Whisper>
            )}
        </ButtonGroup>
    )
}

const today = new Date()
today.setHours(0, 0, 0, 0)

const ranges = [
    {
        label: 'dieser Monat',
        value: [
            new Date(today.getFullYear(), today.getMonth(), 1),
            today
        ]
    },
    {
        label: 'letzter Monat',
        value: [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0)
        ]
    },
    {
        label: 'letzte 30 Tage',
        value: [
            new Date(today.getFullYear(), today.getMonth() - 1, today.getDate() - 31),
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        ]
    },
    {
        label: 'dieses Jahr',
        value: [
            new Date(today.getFullYear(), 0, 1),
            new Date(today.getFullYear(), 11, 31)
        ]
    },
    {
        label: 'letztes Jahr',
        value: [
            new Date(today.getFullYear() - 1, 0, 1),
            new Date(today.getFullYear() - 1, 11, 31)
        ]
    }
]

function DateRange() {
    const dispatch = useDispatch()
    const organization = useSelector(selectOrganization)
    const period = useSelector(selectCurrentPeriod(organization))
    const {periodStart, periodEnd} = useSelector(selectPeriod(period)) ?? {}
    const [valueStart, valueEnd] = useSelector(selectDateRange)
    const {dateRange} = useAccountingStyles()
    const disabledDate = useMemo(
        () => (periodStart && periodEnd) ? DateRangePicker.allowedRange(periodStart, periodEnd) :
            periodStart ? DateRangePicker.after(periodStart) :
                periodEnd ? DateRangePicker.before(periodEnd) :
                    undefined,
        [periodStart, periodEnd]
    )

    return (
        <DateRangePicker
            className={dateRange}
            isoWeek={true}
            size="sm"
            appearance="subtle"
            value={[
                valueStart ?? periodStart ?? new Date(today.getFullYear(), 0, 1),
                valueEnd ?? periodEnd ?? new Date(today.getFullYear(), 11, 31)
            ]}
            showWeekNumbers
            cleanable
            disabledDate={disabledDate}
            character=" — "
            format="D.M.YY"
            placeholder="(ganzes Jahr)"
            ranges={ranges}
            onChange={v => dispatch(setDateRange(v))}
        />
    )
}

function Searchbox() {
    const dispatch = useDispatch()
    const search = useSelector(selectSearch)
    const [value, setValue] = useDebouncedChange(search, 250, value => dispatch(setSearch(value)))

    return (
        <InputGroup inside>
            <Input size="sm" value={value} onChange={v => setValue(v)}/>
            <InputGroup.Button size="sm">
                <Icon icon="search"/>
            </InputGroup.Button>
        </InputGroup>
    )
}
