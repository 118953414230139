import {createSelector, createSlice} from "@reduxjs/toolkit"
import {Container} from "aurelia-dependency-injection"
import {FlashService} from "../../flash/flash-service"
import {setContext} from "./entries-slice"
import {
    accountingBookingLoaded,
    accountingBookingLoading,
    accountingBookingSaved,
    accountingBookingSaveError,
    accountingBookingSaving
} from "./accounting-api"

const flash = Container.instance.get(FlashService)

const editorSlice = createSlice(({
    name: "accounting/editor",
    initialState: {
        open: false,
        loading: false,
        errors: {},
        tab: "simple"
    },
    reducers: {
        setTab: {
            prepare: tab => ({payload: tab}),
            reducer(state, {payload: tab}) {
                state.tab = tab
            }
        },
        closeEditor(state) {
            state.loading = false
            state.open = false
            state.id = undefined
            state.errors = undefined
        },
        setBooking: {
            prepare: booking => ({payload: booking}),
            reducer(state, {payload: booking}) {
                state.booking = booking
            }
        },
        newBooking: {
            prepare: ({organization, period, stack}) => ({payload: {organization, period, stack}}),
            reducer(state, {payload: {period, stack, organization}}) {
                state.open = true
                state.loading = false
                state.id = undefined
                state.errors = undefined
                state.booking = {
                    parts: [{}, {}],
                    bookDate: (new Date).toISOString(),
                    receiptDate: (new Date).toISOString(),
                    receiptNumber: "",
                    period: period ? {id: period, modelId: "accounting/period"} : null,
                    organization: {id: organization, modelId: "organization/organization"},
                    tax: 0,
                    subject: "",
                    costObject: null,
                    reference: null,
                    stack: stack ? {id: stack, modelId: "accounting/stack"} : null
                }
            }
        }
    },
    extraReducers: builder => builder
        .addCase(setContext, (state, {payload: {organization}}) => {
            state.organization = organization
        })
        .addMatcher(accountingBookingLoading, state => {
            state.open = true
            state.loading = true
        })
        .addMatcher(accountingBookingLoaded, (state, {
            payload: {
                id, parts, bookDate, receiptDate, receiptNumber,
                tax, subject, costObject, reference
            }
        }) => {
            state.open = true
            state.loading = false
            state.id = id
            state.tab = 2 === parts.length ? "simple" : "complex"
            state.errors = undefined
            state.booking = {
                parts, bookDate, receiptDate, receiptNumber,
                tax, subject, costObject, reference
            }
        })
        .addMatcher(accountingBookingSaving, state => {
            state.errors = undefined
        })
        .addMatcher(accountingBookingSaved, state => {
            console.error("saved")
            state.loading = false
            state.open = false
            flash.success("Erfolgreich gespeichert")
        })
        .addMatcher(accountingBookingSaveError, (state, {payload: {data, status}}) => {
            if (500 === status) {
                flash.error(data?.localizedMessage ?? data?.message ?? "Ein Fehler ist aufgetreten")
                state.errors = undefined
            } else {
                state.errors = data?.errors?.children ?? {}
                console.error(data?.errors?.children)
            }
        })
}))

const editor = editorSlice.reducer
const selectEditor = createSelector(state => state?.accounting?.editor ?? {}, editor => editor)

export default editor
export const {
    setTab,
    closeEditor,
    setBooking,
    newBooking
} = editorSlice.actions

export const selectEditorDrawer = createSelector(selectEditor, ({open, loading}) => [open, loading])
export const selectBooking = createSelector(selectEditor, ({booking}) => booking)
export const selectBookingId = createSelector(selectEditor, ({id}) => id)
export const selectErrors = createSelector(selectEditor, ({errors}) => errors)
export const selectTab = createSelector(selectEditor, ({tab}) => tab)
