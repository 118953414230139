import {api, onQueryStarted} from "../../store/api"
import {Container} from "aurelia-dependency-injection";
import {FlashService} from "../../flash/flash-service";

/** @type FlashService */
const flash = Container.instance.get(FlashService)

/**
 * Invalidates booking tags after 250ms to let ElasticSearch update first.
 */
function delayedInvalidate(queryArg, {dispatch, queryFulfilled}) {
    const ids = "string" === typeof queryArg ? [id] :
        Array.isArray(queryArg) ? queryArg :
            undefined
    const tags = ids?.length ?
        ids.map(id => ({type: "accounting/booking", id})) :
        ["accounting/booking"];

    queryFulfilled.then(({data}) => {
        if (Array.isArray(data) && "string" === typeof data[0]?.message) {
            flash.flash(data[0]._failed ? "error" : "success", data[0].message)
        }

        if (!data?.[0]?._failed) {
            setTimeout(
                () => {
                    dispatch(api.util.invalidateTags(tags))
                },
                250
            )
        }
    }).catch(error => {
        console.error(error)
        flash.response(error.error, "error")
    })
}

const accountingApi = api.injectEndpoints({
    endpoints: build => ({
        accountingBaseData: build.query({
            query: () => "accounting/base-data",
        }),

        accountingEntries: build.query({
            query: ({context, account, stack, organization, costObject, reference, ...additional}) => {
                const params = new URLSearchParams()
                let url = "accounting/"

                switch (context) {
                    case "account":
                        url += "ledger-account/" + account
                        break

                    case "stack":
                        url += "stack/" + stack
                        break

                    default:
                        url += "ledger-account/organization/" + organization
                }

                if (costObject?.id) {
                    params.set("costObject[id]", costObject.id)
                    params.set("costObject[modelId]", costObject.modelId)
                }

                if (reference?.id) {
                    params.set("reference[id]", reference.id)
                    params.set("reference[modelId]", reference.modelId)
                }

                Object.entries(additional).filter(e => e[1]).forEach(([key, value]) => params.set(key, value))

                url += "/entries?" + params.toString()

                return {url}
            },
            providesTags: (result = []) => [
                "accounting/booking",
                ...result.map(({booking}) => ({type: "accounting/booking", id: booking}))
            ]
        }),

        accountingBookingAction: build.mutation({
            query: ({id, action}) => ({
                url: `accounting/booking/${id}/${action}`,
                method: "PATCH"
            }),
            onQueryStarted(args, definition) {
                return onQueryStarted("Aktion erfolgreich." + ("book" === action ? " Das Festschreiben wird verzögert durchgeführt." : ""))(args, definition)
            },
            invalidatesTags: (result, error, {id}) => [{type: "accounting/booking", id}]
        }),

        loadAccountingBooking: build.query({
            query: id => `accounting/booking/${id}?embeds[]=parts.account`,
            providesTags: ({id}) => ([{type: "accounting/booking", id}])
        }),

        saveAccountingBooking: build.mutation({
            query: (
                {
                    id, parts, bookDate, receiptDate, receiptNumber, tax, subject,
                    costObject, reference, stack, organization
                }
            ) => {
                const body = {
                    parts: parts.map(({account, cr, dr}) => ({account, cr, dr})),
                    bookDate,
                    receiptDate,
                    receiptNumber,
                    tax,
                    subject,
                    costObject,
                    reference,
                    organization,
                    stack
                }

                return id ? ({
                    url: `accounting/booking/${id}`,
                    method: "PUT",
                    body
                }) : ({
                    url: "accounting/booking",
                    method: "POST",
                    body
                })
            },
            onQueryStarted: delayedInvalidate
        }),

        deleteAccountingBooking: build.mutation({
            query: id => ({
                url: `accounting/booking/${id}`,
                method: "DELETE"
            }),
            onQueryStarted: delayedInvalidate
        }),

        clearBookings: build.mutation({
            query: ids => ({
                url: "accounting/bookings-cleared",
                method: "POST",
                body: {
                    bookings: ids.map(id => ({id, modelId: "accounting/booking"}))
                }
            }),
            onQueryStarted: delayedInvalidate
        })
    })
})

export const {
    useAccountingBaseDataQuery,
    useAccountingEntriesQuery,
    useAccountingBookingActionMutation,
    useSaveAccountingBookingMutation,
    useDeleteAccountingBookingMutation,
    useClearBookingsMutation,
    endpoints: {
        accountingBaseData: {
            matchFulfilled: accountingBaseDataLoaded
        },
        accountingEntries: {
            matchFulfilled: accountingEntriesLoaded
        },
        loadAccountingBooking: {
            initiate: loadAccountingBooking,
            matchPending: accountingBookingLoading,
            matchFulfilled: accountingBookingLoaded
        },
        saveAccountingBooking: {
            matchPending: accountingBookingSaving,
            matchFulfilled: accountingBookingSaved,
            matchRejected: accountingBookingSaveError,
        },
        clearBookings: {
            matchFulfilled: clearBookingsFulfilled,
        }
    }
} = accountingApi
